<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <close class="close" @click.prevent="cancelPacketsExpeditionProcess" />
      </template>

      <template v-slot:title>
        <h1>{{ $t("packetsExpedition.title") }}</h1>
      </template>
    </AppHeader>

    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <section class="container">
        <div class="text-center list" v-if="emptyItems">
          <h1>{{ $t("packetsScanned.no-packets") }}</h1>
        </div>
        <Errors :errors="errors" />
        <ScannedListItem
          v-for="(item, index) in items"
          :key="index"
          :success="item.success"
          :scannedValue="item.scanned_value"
          :recipient="item.recipient"
          :status="item.human_result || item.failure_reason"
        >
          <template v-slot:center>
            <p v-if="item.parcel_barcode" class="d-flex align-items-center">
              <package-icon class="package-icon" />
              <strong>{{ parcelCode(item.parcel_barcode) }}</strong>
            </p>
          </template>
        </ScannedListItem>
      </section>
    </main>

    <footer ref="footer">
      <div class="container">
        <a
          class="btn btn-primary btn-white"
          @click.prevent="printLabel"
          v-if="!emptyItems && expeditionId"
          >{{ $t("prints.scanned.expedition.print") }}</a
        >
        <a
          class="btn btn-primary btn-green"
          @click.prevent="cancelPacketsExpeditionProcess"
          >{{ $t("packetsExpedition.finish") }}</a
        >
      </div>
    </footer>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Close from "vue-material-design-icons/Close.vue";
import PackageIcon from "vue-material-design-icons/PackageVariantClosed.vue";
import { setActualFooterHeight } from "@/mixins/footer";
import { getParams } from "@/mixins/platform-params.js";
import { MOBILE_TYPES } from "@/util/mobile.type";
import AppHeader from "@/components/AppHeader";
import Errors from "@/components/Errors";
import ScannedListItem from "@/components/ScannedList/Item";
import Spinner from "@/components/Spinner";

export default {
  name: "PacketsExpeditionProcess",
  mixins: [setActualFooterHeight, getParams],
  components: {
    AppHeader,
    Errors,
    ScannedListItem,
    Spinner,
    Close,
    PackageIcon
  },
  data() {
    return {
      expeditionId: this.$route.params.expeditionId
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    next();
  },

  async mounted() {
    await this.getExpeditionPackets(this.expeditionId);
  },

  computed: {
    ...mapState(["errors", "loading"]),
    ...mapState("scanned", ["items"]),
    ...mapGetters("scanned", ["emptyItems"])
  },

  methods: {
    ...mapActions("scanned", ["getExpeditionPackets", "reprintExpeditionList"]),
    ...mapMutations(["clearErrors"]),

    parcelCode(parcelBarcode) {
      return parcelBarcode.slice(-4);
    },

    cancelPacketsExpeditionProcess() {
      if (!this.isMobile) {
        this.$router.replace({
          name: "dashboard",
          query: {
            platform: this.platform,
            device: this.device
          }
        });

        return;
      }

      if (this.platform == MOBILE_TYPES.ANDROID) {
        window.PacketaPPA.backToScan();
      } else if (this.platform == MOBILE_TYPES.IOS) {
        window.webkit.messageHandlers.backToScan.postMessage({});
      }
    },

    async printLabel() {
      await this.reprintExpeditionList(this.expeditionId);
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  .list {
    margin-top: 30px;
  }
}

footer {
  a:first-child {
    margin-bottom: 10px;
  }
}

.package-icon {
  margin-right: 0.2rem;
}
</style>
